module.exports = {
    // siteUrl: `https://tender-aryabhata-c3b4d0.netlify.app`, // Site domain. Do not include a trailing slash!
    siteUrl: `https://bananatasty.com`, // Site domain. Do not include a trailing slash!

    postsPerPage: 12, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

    siteTitleMeta: `Banana Tasty Presents`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `The Tastiest Art, Games, Musings, and Reviews Straight From Elementary School!`, // This allows an alternative site description for meta data for pages.

    shareImageWidth: 1000, // Change to the width of your default share image
    shareImageHeight: 523, // Change to the height of your default share image

    shortTitle: `Banana Tasty`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `btg-favicon-1.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    backgroundColor: `#e9e9e9`, // Used for Offline Manifest
    themeColor: `#fbf564`, // Used for Offline Manifest
}
